import { renderToString } from "jsx-async-runtime";
import { FORM_ERROR_CONTAINER } from "../constants";

export class FormError extends HTMLElement {
  private _error: string;

  get error(): string {
    return this._error;
  }

  set error(value: string) {
    this._error = value;
  }

  constructor() {
    super();
  }

  async connectedCallback() {
    const html = (
      <div class="form-error">
        <div>{this.error}</div>
      </div>
    );

    this.innerHTML = await renderToString(html);
  }
}

if (!customElements.get(FORM_ERROR_CONTAINER)) {
  customElements.define(FORM_ERROR_CONTAINER, FormError);
}
